/**
 * @license
 * Copyright 2023 Ada School
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { useCallback } from "react";
import { useLocalStorage } from "./useLocalStorage";

const featureToggleKey = "ada-FT";

interface UseFeatureToggle {
  isToggled: (toggleName: string) => boolean;
  setToggle: (toggleName: string) => void;
  clearToggle: (toggleName: string) => void;
}

export const useFeatureToggle = (): UseFeatureToggle => {
  const [storedToggles, setStoredToggles] = useLocalStorage(
    featureToggleKey,
    ""
  );

  const isToggled = useCallback(
    (toggleName: string) =>
      storedToggles.split(",").includes(toggleName.toLowerCase()),
    [storedToggles]
  );

  const setToggle = (toggleName: string) => {
    const currentLocalStorageToggles =
      localStorage.getItem(featureToggleKey) ?? "";

    const allToggles = `${storedToggles},${currentLocalStorageToggles}`;

    const uniqueFeatures = new Set(
      allToggles.split(",").filter((feature) => feature.trim() !== "")
    );

    uniqueFeatures.add(toggleName.toLowerCase());

    setStoredToggles(Array.from(uniqueFeatures.values()).join(","));
  };

  const clearToggle = (toggleName: string) => {
    const toggles = storedToggles
      .split(",")
      .filter((toggle) => toggle !== toggleName.toLowerCase())
      .join(",");
    setStoredToggles(toggles);
  };

  return { isToggled, setToggle, clearToggle };
};
