/**
 * @license
 * Copyright 2023 Ada School
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { useQuery } from "@apollo/client";
import { ChakraProvider, ColorModeScript, Flex } from "@chakra-ui/react";
import { datadogRum } from "@datadog/browser-rum";
import { lazy, useEffect } from "react";
import ReactGA from "react-ga4";
import { CookieConsent } from "./components/CookieConsent";
import { config } from "./config";
import { GetCurrentSchoolDocument } from "./graphql/getCurrentSchool.generated";
import { useUser } from "./providers/useUser";
import { mergeThemeWithDefaults } from "./theme";
import { Helmet } from "react-helmet";
import { useFeatureToggle } from "./hooks/useFeatureToggle";

const App = lazy(() => import("./App"));

export const ThemeSwitcher = () => {
  const { theme, setCurrentSchool, currentSchool, setTheme } = useUser();
  const { data: currentSchoolData, loading: isLoadingSchoolData } = useQuery(
    GetCurrentSchoolDocument
  );
  const { setToggle, isToggled } = useFeatureToggle();

  const featureToggleKey = config.FT_KEY;
  const existFeatureToggleKey = localStorage.getItem(featureToggleKey);

  useEffect(() => {
    if (currentSchoolData) {
      setCurrentSchool(currentSchoolData.currentSchool);
      if (currentSchoolData.currentSchool.theme) {
        setTheme(mergeThemeWithDefaults(currentSchoolData.currentSchool.theme));
      }

      if (
        currentSchoolData.currentSchool.frontendFeatures &&
        currentSchoolData.currentSchool.frontendFeatures.length > 0
      ) {
        for (const frontendFeature of currentSchoolData.currentSchool
          .frontendFeatures) {
          if (isToggled(frontendFeature) || existFeatureToggleKey == null) {
            setToggle(frontendFeature);
          }
        }
      }

      if (
        config.VITE_APP_ENV === "production" &&
        currentSchoolData.currentSchool.googleAnalytics?.tagManagerId
      ) {
        ReactGA.initialize(
          currentSchoolData.currentSchool.googleAnalytics?.tagManagerId,
          {
            nonce: "3BLM0IRTIBnkzFUp5db2aTx8dJ",
          }
        );
      }

      if (config.VITE_APP_ENV === "production") {
        datadogRum.init({
          applicationId: "453c433b-b6d2-4af8-86d1-7072176f9eb3",
          clientToken: "pub0d98b6b88207b812025039807e334582",
          site: "datadoghq.com",
          service: "ada-lms-web",
          env: "prod",
          version: `${String(config.APP_COMMIT_REF)}`,
          sampleRate: 100,
          sessionReplaySampleRate: 15,
          trackFrustrations: true,
          trackInteractions: true,
          enableExperimentalFeatures: ["clickmap"],
          allowedTracingUrls: [/https:\/\/(.*\.)?learn\.ada-school\.org/],
          beforeSend: (event) => {
            event.context = {
              ...event.context,
              schoolId: currentSchool?.id ?? "unknown",
            };
          },
        });
      }
    }
  }, [currentSchoolData]);

  if (isLoadingSchoolData && !currentSchoolData) {
    return (
      <Flex align="center" justify="center" w="100%" h="100vh">
        <svg
          width="120px"
          height="120px"
          viewBox="0 0 100 100"
          preserveAspectRatio="xMidYMid"
        >
          <circle
            cx="50"
            cy="50"
            fill="none"
            stroke="#c5c5c5"
            strokeWidth="3"
            r="13"
            strokeDasharray="61.26105674500097 22.420352248333657"
          >
            <animateTransform
              attributeName="transform"
              type="rotate"
              repeatCount="indefinite"
              dur="1s"
              values="0 50 50;360 50 50"
              keyTimes="0;1"
            ></animateTransform>
          </circle>
        </svg>
      </Flex>
    );
  }

  return (
    // <EmotionCacheProvider nonce="J23OrU3nna08YxqOQNE1UrO9mD">
    <ChakraProvider theme={theme}>
      {theme?.colors?.primary?.["400"] && (
        <Helmet>
          <meta name="msapplication-TileColor" content="#23D5E2" />
          <meta name="theme-color" content="#23D5E2" />
        </Helmet>
      )}

      {currentSchool?.name ? (
        <Helmet>
          <meta name="application-name" content={currentSchool.name} />
          <meta name="description" content={currentSchool.name} />
          <title>{currentSchool.name}</title>
        </Helmet>
      ) : (
        <Helmet>
          <meta name="application-name" content="Learning Platform" />
          <meta name="description" content="Learning platform" />
          <title>Learning Platform</title>
        </Helmet>
      )}

      {currentSchool?.faviconUrl ? (
        <Helmet>
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href={currentSchool.faviconUrl}
          />
        </Helmet>
      ) : (
        <Helmet>
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="/icons/favicon-32x32.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="/icons/favicon-16x16.png"
          />
          <link
            rel="apple-touch-icon"
            sizes="57x57"
            href="/icons/apple-icon-57x57.png"
          />
          <link
            rel="apple-touch-icon"
            sizes="60x60"
            href="/icons/apple-icon-60x60.png"
          />
          <link
            rel="apple-touch-icon"
            sizes="72x72"
            href="/icons/apple-icon-72x72.png"
          />
          <link
            rel="apple-touch-icon"
            sizes="76x76"
            href="/icons/apple-icon-76x76.png"
          />
          <link
            rel="apple-touch-icon"
            sizes="114x114"
            href="/icons/apple-icon-114x114.png"
          />
          <link
            rel="apple-touch-icon"
            sizes="120x120"
            href="/icons/apple-icon-120x120.png"
          />
          <link
            rel="apple-touch-icon"
            sizes="144x144"
            href="/icons/apple-icon-144x144.png"
          />
          <link
            rel="apple-touch-icon"
            sizes="152x152"
            href="/icons/apple-icon-152x152.png"
          />
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="/icons/apple-icon-180x180.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="192x192"
            href="/icons/android-icon-192x192.png"
          />
          <meta
            name="msapplication-TileImage"
            content="/icons/ms-icon-144x144.png"
          />
          <meta
            name="keywords"
            content="online course, soft skills, developer course, developer school, javascript, react, nodeJS, nextJS, skills"
          />
        </Helmet>
      )}

      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <CookieConsent />
      <App />
    </ChakraProvider>
    // </EmotionCacheProvider>
  );
};
